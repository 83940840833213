import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import ServiceHero from "../components/ServiceHero"
import AboutInvisalign from "../components/AboutInvisalign"
import Offer from "../components/Offer"
import Testimonials from "../components/Testimonials"

const Invisalign = () => (
  <>
    <Layout>
      <Seo title="About Invisalign" />
      <ServiceHero
        title="Invisalign in Santa Rosa Beach, FL"
        heroText="Join the 10 Million smiles with Invisalign. It just works! Invisalign
          straightens teeth more comfortably and for all simple and complex
          shifts."
      />
      <AboutInvisalign />
      <Offer />
      <Testimonials />
    </Layout>
  </>
)

export default Invisalign
